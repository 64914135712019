<template>
  <div class="bulkCommandAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('menu.bulkCommand') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>Name</label>
        <input
          v-model="bulkCommand.name"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('applicationType') }}</label>
        <textarea
          id="comment"
          v-model="bulkCommand.applicationType"
          placeholder="Type in one or more Application types, seperated with semicolon. Leave empty if it's for all types"
          rows="3"
          class="form-control"
        />
        <div class="form-check form-check-inline mb-3 mt-2">
          <input
            id="startsWith"
            v-model="bulkCommand.startsWith"
            class="form-check-input"
            type="checkbox"
          >
          <label
            class="form-check-label"
            for="startsWith"
          >Starts With</label>
        </div>
        <hr class="m-0 mb-3">
        <label>Remote Command</label>
        <input
          v-model="bulkCommand.remoteCommand"
          class="form-control"
          type="text"
        >
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!bulkCommand.name || !bulkCommand.remoteCommand"
        @click.prevent="editRemoteCommand()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('edit') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
export default {
  name: "BulkCommandEdit",
  props: {
    bulkCommand: {
      type: Object,
      required: true
    }
  },
  methods: {
    async editRemoteCommand () {
      await this.axios.put('/CentralDeviceManagement/EditDeviceBulkCommand', this.bulkCommand)
        .then(() => {
          this.$snotify.success(this.$t('bulkCommand.updatedSuccessfully'));
          this.$emit("reload");
        })
    }
  }
}
</script>

<style scoped>
.bulkCommandAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>